import axios from "axios";
import axiosRetry from "axios-retry";
import { useToast } from "vue-toastification";

const toast = useToast();

const instance = axios.create({
    timeout: 150000,  // timeout for each request 150s
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest',
            // Any other common headers can go here
        }
    }
});

/*
 * axios-retry will automatically retry the request, based on the configured options
 * You can override the retry options and the timeout on a per-request basis by specifying the following options:
 * https://www.npmjs.com/package/axios-retry#:~:text=//%20Allows%20request%2Dspecific%20configuration
    {
        timeout: 100000,
        'axios-retry': { retries: 3 },
    }
 */
axiosRetry(instance, {
    retries: 1,
    // resets the timeout for each retry, ensuring every retry gets the full timeout duration,
    // mitigating failures due to timeout exceeding the delay between retries.
    shouldResetTimeout: true,
    retryCondition: (error) => {
        // Return true if error was not caused by a 401 Unauthorized response
        // By default, it retries if it is a network error or a 5xx error on an idempotent request (GET, HEAD, OPTIONS, PUT or DELETE).
        // Retry if error occurred while setting up the request
        if (!error.response || axiosRetry.isNetworkOrIdempotentRequestError(error)) {
            return true;
        }
    },
    onMaxRetryTimesExceeded: function (error, retryCount) {
        console.error('All retry attempts exhausted.')

        if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
            toast.clear();
            toast.error('A network error occurred.');
        }
    },
    retryDelay: axiosRetry.exponentialDelay
});

instance.interceptors.response.use(response => {
    return response;
}, error => {

    if (error.response && [419].includes(error.response.status)) {
        location.reload();
    }

    return Promise.reject(error);
});

export default instance;