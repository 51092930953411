import { createApp, getCurrentInstance, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import FloatingVue from 'floating-vue'
import Toast, { POSITION, TYPE } from "vue-toastification";
import _ from "lodash";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import '../sass/app.scss';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import SentryPlugin from "@/Plugins/SentryPlugin";
import axiosClient from "@/axiosClient";
import mitt from "mitt";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || import.meta.env.VITE_APP_NAME;

const emitter = mitt();

window._ = _;

window.axios = axiosClient;

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
    encrypted: true,
});

//https://inertiajs.com/client-side-setup#initialize-the-inertia-app
//https://inertiajs.com/upgrade-guide
const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        let pages = import.meta.glob("./Pages/**/*.vue");
        const importPage = pages[`./Pages/${name}.vue`];
        return (await importPage()).default;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({
            mounted() {
                let user = props.initialPage.props?.auth?.user;
                if (user) {
                    getCurrentInstance().appContext.config.globalProperties.$sentry.setUser({
                        id: user.id,
                        name: user.name,
                        email: user.email,
                    });
                }
            },
            render: () => h(App, props)
        })
            .use(plugin)
            .use(FloatingVue)
            .use(Toast, {
                closeButton: false,
                hideProgressBar: true,
                position: POSITION.BOTTOM_CENTER,
                toastDefaults: {
                    [TYPE.ERROR]: {
                        timeout: 10000,
                        closeButton: false,
                        hideProgressBar: false,
                    },
                    [TYPE.SUCCESS]: {
                        timeout: 6000,
                        hideProgressBar: true,
                    }
                }
            })
            .use(SentryPlugin)
            .mixin({ methods: { route: window.route } });    // `@routes` directive injects `route` onto `window`

        app.config.globalProperties.$emitter = emitter;
        app.mount(el);
    },
    progress: {
        color: '#4B5563'
    },
});