import * as Sentry from "@sentry/vue";

export default {
    install: (app, options) => {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.VITE_APP_ENV,
            trackComponents: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.captureConsoleIntegration({
                    levels: ['error']
                }),
                Sentry.replayIntegration()
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost'],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        app.config.globalProperties.$sentry = Sentry;
    }
}